.portfolio {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    
    height: 439px;
}

.portfolio h2 {
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    text-shadow: 4px 6px 0px rgba(0, 0, 0, 0.45);
    font-family: "Azo Sans";
    text-align: center;
    z-index: 1;
   
}

.h2bg {
    background-color: rgba(0,71,60,255);
    width: 400px;
    height: 200px;
    z-index: 0;
}



.portfolio-sections {
    height: 439px;
    max-height: 439px;
    min-height: 439px;
    gap: 102px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portrait-lifestyle {
    display: inline-flex;
    width: 498px;
    height: 332px;
    overflow: hidden;
    z-index: 4;
}

.portrait-lifestyle img {
    z-index: 3;
}



.Engagement-work {
    display: inline-flex;
    width: 498px;
    height: 332px;
    overflow: hidden;
}

.portfolio img {
    z-index: 4;
    width: 498px;
    height: 280px;
    
}

.portfolio img:hover {
    cursor: pointer;
}



.portfolio-backgrounds {
    z-index: 0;
    position: relative;
    bottom: 340px;
    display: inline-flex;
    justify-items: center;
    justify-content: center;
    gap: 40px;
    /*Distance between this section and block is 89px */
}

.portrait-background {
    
    display: inline-block;
    position: relative;
    width: 560px;
    height: 200px;
    background: linear-gradient(180deg, rgba(6,92,187,255) 0%, rgba(7,50,97,255) 100%);
}

.Engagement-background {
    display: inline-block;
    position: relative;
    width: 560px;
    height: 200px;
    background: linear-gradient(180deg, rgba(147,39,143,255) 0%, rgba(76,28,117,255) 100%);
}
.engagement-menu {
    display: inline-flex;
    flex-direction: column;
    width: 498px;
    color: white;
    font-family: "Azo Sans";
    z-index: 1;
    
    
}

.portrait-menu {
    display: flex;
    flex-direction: column;
    width: 502px;
    color: white;
    font-family: "Azo Sans";
    z-index: 0;
    

}

.portrait-reveal {
    
    position: absolute;
    width: 560px;
    height: 250px;

    animation-name: portrait-anim;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    background: linear-gradient(180deg, rgba(6,92,187,255) 0%, rgba(7,50,97,255) 100%);
    z-index: 0;
}

@keyframes portrait-anim{
    0% {width:560px; height:250px; background: linear-gradient(180deg, rgba(6,92,187,255) 0%, rgba(7,50,97,255) 100%);}
    100% {width:100vw; height:1000px; background: linear-gradient(180deg, rgba(6,92,187,255) 0%, rgba(7,50,97,255) 100%);}
}

@keyframes engagement-anim{
    0% {width:560px; height:250px; background: linear-gradient(180deg, rgba(147,39,143,255) 0%, rgba(76,28,117,255) 100%);}
    100% {width:100vw; height:1000px; background: linear-gradient(180deg, rgba(147,39,143,255) 0%, rgba(76,28,117,255) 100%);}
}

.engagement-reveal {
    
    position: absolute;
    width: 560px;
    height: 250px;

    animation-name: engagement-anim;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    background: linear-gradient(180deg, rgba(147,39,143,255) 0%, rgba(76,28,117,255) 100%);
    z-index: 0;
}

.portfolio-mobile {
    display: none;
}
@media only screen and (max-width: 800px) {
    .portfolio {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .portfolio-backgrounds {
        display: none;
    }
    .portfolio-sections {
        display: none;
    }
    .portfolio-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 350px;
        gap: 20px;
    }
    .create-mobile-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 350px;
        color: white;
    }
    .portfolio-mobile img {
        max-width: 350px;
    }
}