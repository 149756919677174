.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-radius: 1rem;
    margin: 0 10px 10px;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-family: "Azo Sans";
    font-weight: 700;
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: green;
    border-radius: 1rem 1rem 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
    
   
  }

