.Homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    width: auto;
    height: 2200px;
    

    background-image: linear-gradient(135deg, #001612 45.45%, #009986 45.45%, #009986 50%, #001612 50%, #001612 95.45%, #009986 95.45%, #1a1942 100%);
    background-size: 12px 12px;
}

.Homepage p {
    line-height: 18px;
    letter-spacing: 0.02em;
}
.banner {
    position: absolute;
    z-index: 1;
}
.banner img {
    width: 100vw;
    max-height: 800px;
}
.welcome-intro {
    height: 524px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-bottom: 180px;
    padding-top: 50px;
}

.text-intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 490px;
    text-align: left;
    color: white;
}

.text-intro h1 {
    
    font-family: "Azo Sans";
    margin-bottom: -20px;
    margin-left: 0px;
}
.text-intro h3 {
    font-family: "Azo Sans";
    margin-bottom: -12px;
}
.text-intro h5 {
    font-family: "Azo Sans";
    color: rgb(219, 219, 219);
    margin-left: 0px;
}
.text-intro h6 {
    font-family: "Azo Sans";
    font-size: 19px;
    letter-spacing: 0.1em;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
}
.text-intro h3 {
    font-family: "Azo Sans";
    margin-bottom: -12px;
}
.text-intro h4 {
    font-family: "Azo Sans";
    font-size: 12px;
}
.text-intro p {
    line-height: 18px;
    width: 400px;
    letter-spacing: 0.02em;
}
.image-intro img {
    max-height: 500px;
}

.CTA {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 580px;
    width: 300px;
    margin-left: 50px;
}

.CTA button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(7,50,97,255);
    border-radius: 10px;
    background-color: rgba(6,92,187,255);
    color: white;
    font-family: "Azo Sans";
    font-size: 16px;
    font-weight: 700;
    width: 200px;
    height: 50px;
    transition: background-color 0.3s ease-in;
    margin-top: 0px;
    margin-bottom: 60px;
}

.CTA button:hover {
    cursor:pointer;
    background-color:rgba(7,50,97,255);
}
.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.current-ad {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
}

.current-ad img {
    margin-top: 20px;
}

.current-ad img:hover {
    cursor: pointer;
}
.current-ad a {
    
    width: 300px;
}
.footer-banner {
    width: 100vw;
    position: absolute;
    top: 1900px;
    height: 400px;
    display: flex;
    z-index: 2;
}
.footer-banner img {
    width: 100vw;
    transform: rotateX(180);
    object-fit: cover;
    
}
.footer-notes{
    position: absolute;
    top: 1950px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    height: 300px;
}

.footer-notes button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(7,50,97,255);
    border-radius: 10px;
    background-color: rgba(6,92,187,255);
    color: white;
    font-family: "Azo Sans";
    font-size: 16px;
    font-weight: 700;
    width: 200px;
    height: 50px;
    transition: background-color 0.3s ease-in;
    margin-top: 20px;
    margin-bottom: 60px;
}

.footer-notes button:hover {
    cursor:pointer;
    background-color:rgba(7,50,97,255);
}

.footer-notes p {
    color: white;
}

.camps-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 3;
    color: white;
    font-family: "Azo Sans";
    position: absolute;
    top: 1500px;
    gap: 100px;
}

.camps-text {
    width: 400px;
}

.camps-info-bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 700px;
    position: absolute;
    top: 1400px;
    z-index: 1;
}

.camps-info-bg img {
    width: 100vw;
}

.camps-info-darken {
    width: 100vw;
    width: 100vw;
    height: 700px;
    position: absolute;
    top: 1400px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4)
}

.invisible-helper {
    z-index: 10;
    width: 100vw;
    height: 200px;
    
    position: absolute;
    top: 1225px;
}
.invisible-helper2 {
    z-index: 10;
    width: 95px;
    height: 500px;
    position: absolute;
    top: 935px;
}
.invisible-helper3 {
    z-index: 10;
    width: 95px;
    height: 500px;
    position: absolute;
    top: 835px;
    margin-right: 1200px;
}
.invisible-helper4 {
    z-index: 10;
    width: 95px;
    height: 500px;
    position: absolute;
    top: 835px;
    margin-left: 1200px;
}
.invisible-helper5 {
    z-index: 10;
    width: 100vw;
    height: 100px;
    position: absolute;
    top: 842px;
}

@media only screen and (max-width: 800px) {
    .Homepage {
        height: 2520px;
    }
    .welcome-intro {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0px;
        height: 600px;
        margin-bottom: -100px;
    }
    .text-intro {
        width: 350px;
    }
    .text-intro p {
        width: 350px;
    }
    .image-intro img {
        display: none;
    }
    .banner img {
        object-fit: cover;
        height: 700px;
    }
    .banner {
        overflow: hidden;
    }
    .CTA {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: static;
        width: 300px;
        margin-left: 20px;
        margin-top: 50px;
    }
    .camps-info {
        top: 1650px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0px;
        width: 350px;
        

    }
    .camps-text {
        width: 350px;
    }
    .camps-info-bg {
        top: 1600px;
        overflow: hidden;
    }
    .camps-info-bg img {
        width: 1200px;
    }
    .camps-info-darken {
        top: 1600px;
    }
    .footer-banner {
        top: 2200px;
        overflow: hidden;
    }
    .footer-banner img {
        height: 500px;
    }
    .footer-notes {
        top: 2250px;
        width: 350px;
    }
    .footer-notes h1 {
        font-size: 20px;
    }
}