.camps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}

.main-banner {
  position:relative;
  top: -40px;
}
.main-banner img {
  max-width: 100vw;
}
.instructions {
  margin-bottom: 20px;
}
.instructions h2 {
  color: black;
}
.camps-layout {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.bg {
  position: absolute;
  display: none;
  z-index: 0;
}


.information-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
}
.logo img {
  max-width: 150px;
  margin-left: -20px;
  margin-right: -20px;
}

.logo2 {
  display: flex;
  width: 300px;
}
.logo2 img {
  max-width: 300px;
}

.camp-image {
  display: flex;
  justify-content: center;
}

.AMPM {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.reservation-info {
  display: flex;
  width: 300px;
  justify-content: center;
  text-align: center;
}

.reservation-info-mobile {
  display: none;
}

.location-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
}

.location-buttons img {
  max-width: 60px;
}
.location-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  text-align: center;
}

.main-grid {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 730px;
}

.grid-background {
  position: absolute;
  width: 1000px;
  height: 850px;
  z-index: 0;
}


.grid-background img {
  width: 1000px;
  height: 850px;
  object-fit: fill;
  z-index: 0;
}

.grid-layout {
  position: relative;
  top: 6px;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 339px repeat(9, 55px);
  grid-template-rows: repeat(19, 38.4px);
  justify-items: center;
  align-items: center;
  z-index: 2;
}

.item1 {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
}

.grid-layout h4 {
  justify-self: center;
  align-self: center;
  font-family: "Azo Sans";
  font-size: 14px;
  text-decoration: underline;
}

.grid-layout img {
  height: 40px;
  transition: height .2s ease-in;
  cursor: pointer;
  object-fit: cover;
}

.grid-layout img:hover{
  height: 50px;
}

.time-button {
  position: relative;
  right: 3px;
  top: 3px;
}

.camp-category {
  display: flex;
  justify-self: left;
  align-items: center;
  gap: 10px;
  margin-left: 5px;
}

.footer {
  position: absolute;
  bottom: -650px;
  z-index: 2;
  color:white;
}

.footer-image {
  display: flex;
  width: 100vw;
}

.footer-image img {
  max-width: 100vw;
}

.camp-category img:hover {
  height: 40px;
  cursor: default;
}

.mobile-layout {
  display: none;
}

.grid-background-mobile{
  display: none;
}


@media only screen and (max-width: 800px) {
  .nav {
      background-color: white;
      gap: 2px;
      
  }
  .main-banner {
    display: none;
  }
  .camps-layout {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    width: 350px;
  }
  .logo {
    display: none;
  }
  .location-info {
    display: none;
  }
  .camp-image {
    display: none;
  }

  .reservation-info {
    display: none;
  }

  .reservation-info-mobile {
    display: flex;
    width: 300px;
    height: 112px;
    justify-content: center;
    text-align: center;
  }
  .main-grid {
    display: none;
  }
  .mobile-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-layout h1 {
    color: black;
  }
  .months {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .grid-layout-mobile {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 160px repeat(4, 55px);
    grid-template-rows: repeat(19, 59px);
    justify-items: center;
    align-items: center;
    z-index: 3;
  }

  .grid-layout-mobile h5{
    color: white;
  }
  .grid-layout-mobile h4 {
    justify-self: center;
    align-self: center;
    font-family: "Azo Sans";
    font-size: 10px;
    text-decoration: underline;
    color: black;
  }
  .grid-layout-mobile h1 {
    color: white;
  }
  
  .grid-layout-mobile img {
    height: 40px;
    transition: height .2s ease-in;
    cursor: pointer;
    object-fit: cover;
    z-index: 0;
  }

  .grid-background-mobile {
    display: block;
    position: absolute;
    top: 510px;
    width: 390px;
    height: 1350px;
    z-index: -3;
    overflow: hidden;
  }
  
  
  .grid-background-mobile img {
    width: 1050px;
    height: 1290px;
    
    z-index: 0;
    position: relative;
    left: -192px;
    
  }
  .footer {
    display: none;
  }
  .footer-image {
    position: absolute;
    top: 1900px;
  }
}