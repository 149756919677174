.contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    height: 830px;
}

.contact-methods p {
    color:white;
}
.logo-brand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 600px;
}
.logo-brand img {
    max-width: 600px;
    
}

.contact-cta {
    position: relative;
    z-index: 2;
    left: -390px;
    top: 30px;
    margin-right: -200px;
    margin-left: -100px;
}

.getintouch2 {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    
    width: 688px;
    height: 600px;
    background: #4873ab;
    padding: 20px 40px 40px;
    margin-right: 0px;
    
}

.name-email {
    display: inline-flex;
    flex-direction: row;
    gap: 30px;
}

.textfield {
    display: inline-flex;
    flex-direction: column;
    justify-items: flex-start;
    width: 50%;
}

.textfield input {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.selection {
    display: inline-flex;
    flex-direction: column;
    justify-items: flex-start;
}

.message {
    display: inline-flex;
    flex-direction: column;
    justify-items: flex-start;
}

.message-input {
    height: 148px;
    color: white;
    font-family: "Azo Sans";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    background: #376195;
    border-bottom: 1px solid #7eb9ca;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 10px;
    gap: 10px;
    resize: none;
}

form {
    display: inline-flex;
    flex-direction: column;
    justify-items: flex-start;
    gap: 30px;
}

label {
    font-family: "Azo Sans";
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

input {
    background: #376195;
    border-bottom: 1px solid #7eb9ca;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 10px;
    gap: 10px;
    color: white;
    font-family: "Azo Sans";
    z-index: 2;
    transition: border-bottom 0.3s ease-in;
}

select {
    background: #376195;
    border-bottom: 1px solid #7eb9ca;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 235px;
    height: 31px;
    color: white;
    font-family: "Azo Sans";
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    z-index: 2;
    transition: border-bottom 0.3s ease-in;
}


textarea {
    transition: border-bottom 0.3s ease-in;
}

option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 3px;
    height: 31px;

    background: #376195;
    border-width: 0px 1px;
    border-style: solid;
    border-color: #0A0620;
}


input:hover {
    border-bottom: 1px solid white;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
}

textarea:hover {
    border-bottom: 1px solid white;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
}

select:hover {
    border-bottom: 1px solid white;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
}

.submit {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.getintouch2 button:hover {
    cursor: pointer;

}

.getintouch2 button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 19px;
    gap: 10px;
    width: 158px;
    height: 37px;
    background: #c56019;
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: none;
}

.getintouch2 button:disabled {
    background: gray;
    cursor: auto;
}

.contact-methods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: space-between;
    margin: 0px 60px;
    margin-top: 40px;

}

.contact-item {
    display: flex;
    height: 15px;
    flex-direction: row;
    align-items: center;
    gap: 6px;

}

.contact-item p {
    font-family: "Azo Sans";
    font-size: 12px;
    line-height: 15px;
}

.contact-splash {
    display: flex;
    position: absolute;
    top: -20px;
    z-index: -1;
    max-height: 100vh;
    
}

.contact-splash img {
    object-fit: cover;
    height: 100vh;
    min-width: 120vw;
    opacity: 0.7;
}

.green-square {
    display: flex;
    flex-shrink: 0;
    width: 710px;
    height: 594px;
    position: absolute;
    left: 233px;
    top: 136px;
    background: linear-gradient(180deg, #4D7B8E 0%, rgba(77, 123, 142, 0) 100%);
    z-index: 0;
}

@media only screen and (max-width: 800px) {
    .logo-brand {
        height: 600px;
    }
    .logo-brand img {
        max-width: 300px;
    }
    .green-square {
        display: none;
    }
    .name-email {
        flex-direction: column;
    }
    .textfield {
        width: 300px;
    }
    .contact {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 350px;
        gap: 0px;
        height: 1200px;
        margin-top: 60px;
    }
    .contact-splash img {
        height: 330px;
        width: 100vw;
        max-width: 100vw;
        min-width: auto;
    }
    .contact-splash {
        display: flex;
        position: absolute;
        z-index: -1;
        height: 400px;
        overflow: hidden;
        
    }
    
    .getintouch2 {
        width: 350px;
        height: 900px;
        align-items: center;
    }
    .contact-methods {
        flex-direction: column;
        gap: 20px;
    }
    select {
        width: 300px;
    }
    .contact-cta {
        position: absolute;
        z-index: 2;
        left: 38%;
        top: 150px;
        font-size: 12px;
        width: 200px;


    }
    .hide-mobile {
        display: none;
    }
    .green-square {
        display: none;
    }
    
}