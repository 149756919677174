@font-face {
  font-family: 'Azo Sans';
  src: local('Azo Sans Regular'), local('Azo-Sans-Regular'),
      url('AzoSans-Regular.woff2') format('woff2'),
      url('AzoSans-Regular.woff') format('woff'),
      url('AzoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Azo Sans';
  src: local('Azo Sans Bold'), local('Azo-Sans-Bold'),
      url('AzoSans-Bold.woff2') format('woff2'),
      url('AzoSans-Bold.woff') format('woff'),
      url('AzoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

p {
  font-family: "Azo Sans";
  font-weight: 400;
  font-size: 14px;
  margin: 2px;
}

a {
  font-family: "Azo Sans";
  font-weight: 700;
  font-size: 14px;
  margin: 2px;
}

h6 {
  font-family: "Azo Sans";
  font-weight: 700;
  font-size: 14px;
  margin: 5px;
}

h5 {
  font-family: "Azo Sans";
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-align: center;
  
}
h2 {
  font-family: "Azo Sans";
  font-weight: 700;
  color: white;

}
h1 {
  font-family: "Azo Sans";
  font-weight: 700;
  color: white;
  
}