.camp-description {
    position: fixed;
    top: 300px;
    left: 150px;
    width: 400px;
    height: 300px;
    z-index: 3;
    border: 2px solid black;
    border-radius: 2px;
    transition: display .3s ease-in;
}

.camp-description img {
    max-width: 400px;
    height: 300px;
}