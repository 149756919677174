.nav {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    gap: 36px;
    padding: 18px 26px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 11;
    background: rgba(255, 255, 255, 0.0);
    transition: background 0.1s ease-in;
}

.main-page-link img {
    height: 40px;
    position: relative;
    top: 1px;
    
}

.nav a{
    
    text-decoration: underline;
    color: black;
    background-color: none;
    
    
}

.nav-item a:hover {
    
    background-color: #338fbf;
    color: white;
    text-decoration: none;
}

.main-page-link a:hover{
    background-color: none;
    color: black;
    text-decoration: none;
}

html:not([data-scroll='0']) {
    .nav {
      border-bottom: 1px solid #000000;
      background: rgba(255, 255, 255, 1);
    }
  }

  .wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrap:hover{
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    left: 50px;
    top: 20px;
    width: 150px;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 4px 0px #338fbf;
    
}
.dropdown-button {
    width: 150px;
}

.dropdown-content a:hover{
    background-color: #338fbf;
}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.dropdown h6 {
    text-decoration: underline;
}

@media only screen and (max-width: 800px) {

    .main-page-link img{
        max-width: 100px;
    }
}


